import React, { useState, useEffect } from 'react';
import { useNavigate, useParams  } from "react-router-dom";
import { CCol, CRow } from '@coreui/react';
import CarMarketService from 'src/services/CarMarketService';
import { message } from 'antd';

const moneyFormat = (value) => new Intl.NumberFormat().format(value);

const EditarContado = () => {
	const navigate = useNavigate();
  const [abonosT, setAbonosT] = useState([]);
  const [formData, setFormData] = useState({
    abonos: 1,
    tipoSeparacion: '',
    responsabilidadGastos: '',
    precioVehiculo: '',
    clase: '',
    marca: '',
    tipo: '',
    modelo: '',
    color: '',
    placa: '',
    chasis: '',
    motor: '',
    linea: '',
    servicio: '',
    abono1: '',
    fechaAbono1: '',
    abono2: '',
    fechaAbono2: '',
    abono3: '',
    fechaAbono3: '',
    fechaContrato: ''
  });
  var { id } = useParams();

  const handleChangeAbonos = (index, event) => {
    const { name, value } = event.target;
    const updatedAbonos = [...abonosT];
    updatedAbonos[index][name] = value;
    setAbonosT(updatedAbonos);
  };

  const handleChange = (e) => {
    if(e.target.name === 'abonos'){      
      setAbonosT(e.target.value)
      const cantidad = parseInt(e.target.value, 10);
      // Actualiza el estado de abonos según la cantidad seleccionada
      if (cantidad > abonosT.length) {
        const nuevosAbonos = [...abonosT, ...Array(cantidad - abonosT.length).fill({ abono: '', fecha: '', banco: '' })];
        setAbonosT(nuevosAbonos);
      } else {
        setAbonosT(abonosT.slice(0, cantidad));
      }
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();    
    let _data = {
      cm_id: id,  
      fecha_contrato: formData.fecha_contrato ? formData.fecha_contrato : '',
      abonos: abonosT
    }
    console.log(_data)

    CarMarketService.updateCarMarket(_data).then(() =>{
      message.success("Registro actualizado con éxito")
      setTimeout(() => {
        volver()
      }, 2000);
    })
  };

  const volver = () =>{
	  navigate(`/vehiculos/editar/${formData.id}`);
  }

  useEffect(() => {
    CarMarketService.getCarMarketById(id).then(data =>{
     let _data = data.data;
     if(_data.length > 0){
      _data = _data[0]
      let _car = {
        id: _data.id,
        abonos: _data.nro_abonos,
        tipoSeparacion: _data.tipo_separacion,
        responsabilidadGastos: _data.resp_gastos,
        precioVehiculo: moneyFormat(_data.price),
        clase: _data.vehicle_body_type,
        marca: _data.make,
        tipo: _data.vehicle_type,
        modelo: _data.model,
        color: _data.color,
        placa: _data.plate,
        chasis: _data.chasis,
        motor: _data.motor,
        linea: _data.version,
        servicio: _data.servicio,
        separacion: _data.abono,
        fecha_separacion: _data.fecha,
        banco_separacion: _data.banco,
        fecha_contrato: _data.fecha_contrato,
      }
      setAbonosT(_data.nro_abonos)
      const cantidad = parseInt(_data.nro_abonos, 10);
      let nuevosAbonos = Array(cantidad).fill({ abono: '', fecha: '', banco: '' });

      let arrayAbonos = _data.abonos;
      for (let index = 0; index < _data.nro_abonos; index++) {
        if(arrayAbonos[index] && arrayAbonos[index].fecha != ''){
          const date = new Date(arrayAbonos[index].fecha);
          const formattedDate = date.toISOString().split('T')[0];
          nuevosAbonos[index] = { abono: moneyFormat(arrayAbonos[index].abono), fecha: formattedDate, banco: arrayAbonos[index].banco, pago: true };
        }else{
          nuevosAbonos[index] = { abono: '', fecha: '', banco: '', pago: false };
        }
      }
      setAbonosT(nuevosAbonos);

      setFormData(_car)
     }
    })
  }, [null])

  return (
     <div className="contado">
     	 <form className="car-form" onSubmit={handleSubmit}>
      <h2 className="car-title">{formData.placa}</h2>

      <div className="form-row">
        <div className="form-group">
          <label>Precio del Vehículo</label>
          <input
            type="text"
            name="precioVehiculo"
            value={formData.precioVehiculo}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Nº de abonos</label>
          <input
            type="number"
            min={1}
            max={10}
            name="abonos"
            value={formData.abonos}
            placeholder='Nº de abonos'
            disabled
          />
        </div>

        <div className="form-group">
        <label>&nbsp;</label>
          <select name="tipoSeparacion" value={formData.tipoSeparacion} onChange={handleChange} disabled>
            <option value="">Tipo de separación</option>
            <option value="TRIPLO">TRIPLO</option>
            <option value="COMPRADOR">COMPRADOR</option>
          </select>
        </div>

        <div className="form-group">
        <label>&nbsp;</label>
          <select name="responsabilidadGastos" value={formData.responsabilidadGastos} onChange={handleChange} disabled>
            <option value="">Responsabilidad de gastos</option>
            <option value="Proporciones iguales">Proporciones iguales</option>
            <option value="Prorrateo de impuestos y soat">Prorrateo de impuestos y soat</option>
            <option value="Comprador">Comprador</option>
            <option value="Vendedor">Vendedor</option>
          </select>
        </div>
      </div>
      <CRow>
      	<CCol xs={3}>
      		<div className="form-group">
          <label>Clase</label>
          <input
            type="text"
            name="clase"
            placeholder='Ener class'
            value={formData.clase}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Marca</label>
          <input
            type="text"
            name="marca"
            placeholder='Enter brand'
            value={formData.marca}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Tipo</label>
          <input
            type="text"
            name="tipo"
            placeholder='Enter type'
            value={formData.tipo}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Modelo</label>
          <input
            type="text"
            placeholder='Enter color'
            name="modelo"
            value={formData.modelo}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Color</label>
          <input
            type="text"
            name="color"
            placeholder='Enter color'
            value={formData.color}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Placa</label>
          <input
            type="text"
            name="placa"
            placeholder='Enter plate number'
            value={formData.placa}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Chasis</label>
          <input
            type="text"
            name="chasis"
            placeholder='Enter chasis number'
            value={formData.chasis}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Motor</label>
          <input
            type="text"
            name="motor"
            placeholder='Enter motor number'
            value={formData.motor}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Línea</label>
          <input
            type="text"
            name="linea"
            placeholder='Enter linea'
            value={formData.linea}
            disabled
          />
        </div>

        <div className="form-group">
          <label>Servicio</label>
          <input
            type="text"
            name="servicio"
            placeholder='Enter service'
            value={formData.servicio}
            disabled
          />
        </div>
      	</CCol>
      	<CCol xs={9}>
          {abonosT.map((abono, index) => (
            <CRow className="mb-3">
              <CCol xs={6}>
                <div className="form-group">
                  <label>{ index == 0 ? 'Separacion' : `Abono ${index}` }</label>
                  <input
                    type="text"
                    name="abono"
                    value={abono.abono}
                    disabled={ abono.pago }
                    onChange={(e) => handleChangeAbonos(index, e)}
                  />
                </div>
              </CCol>
              <CCol xs={6}>
                <div className="form-group">
                  <label>{ index == 0 ? 'Fecha de separacion' : `Fecha Abono ${index}` }</label>
                  <input
                    type="date"
                    name="fecha"
                    value={abono.fecha}
                    disabled={ abono.pago }
                    onChange={(e) => handleChangeAbonos(index, e)}
                  />
                </div>
              </CCol>
              <CCol xs={12}>
                <div className="form-group">
                  <input
                      type="text"
                      name="banco"
                      value={abono.banco}
                      placeholder="Datos del banco"
                      disabled={ abono.pago }
                      onChange={(e) => handleChangeAbonos(index, e)}
                    />
                    </div>
              </CCol>
            </CRow>
          ))}
      		<CRow className="mb-3">
      			<CCol xs={12}>
      				<div className="form-group">
				        <label>Fecha de celebración de contrato</label>
				        <input
				          type="date"
				          name="fecha_contrato"
                  placeholder='DD/MM/YYYY'
				          value={formData.fecha_contrato}
				          onChange={handleChange}
				        />
				      </div>
      			</CCol>
      		</CRow>
      
      	</CCol>
      </CRow>

      <div className="form-actions">
        <button type="submit" className="save-btn">Guardar</button>
        <button type="button" className="cancel-btn" onClick={ volver }>Cancelar</button>
      </div>
    </form>
     </div>
  );
};

export default EditarContado;
