import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { CRow, CCol, CCarousel, CCarouselItem, CFormLabel } from '@coreui/react';
import VehiculosService from "src/services/VehiculosService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormOutlined } from '@ant-design/icons';
import { Link  } from "react-router-dom";

const moneyFormat = (value) => new Intl.NumberFormat().format(value);

const ViewCar = () =>{
    const [car, setCar] = useState({});
    var { id } = useParams();
    const [photos, setPhotos] = useState([]);
    const [estadoPro, setEstadoPro] = useState(0);

    const getCar = () =>{
        VehiculosService.get(id).then(resp =>{
            let _resp = resp.response;
            setCar(resp.response);
            getStatus(resp.response);
            let x = 0;
            if(_resp.nro_abonos){
                x = (_resp.nbonos * 100) / _resp.nro_abonos;
            }
            setEstadoPro(x)

            if(resp.response.pictures){
                let photos = resp.response.pictures.split(',');
                setPhotos(photos);  
            }          
        }).catch(error => console.log(error));
    }

    const getStatus = (_car) => {
        if (_car.status_im === 'inactive' && _car.published === 0) {
            return <span className="badge text-bg-danger">No</span>;
        } else if (_car.status_im === 'active' && _car.published === 0) {
            return <span className="badge text-bg-warning">Mercado libre</span>;
        } else if (_car.status_im === 'active' && _car.published === 1) {
            return <span className="badge text-bg-success">Si</span>;
        }
        return null; // Por si no cumple ninguna de las condiciones
    };

    useEffect(() => {
        getCar();
    }, [])

    return(
        <>
            <CRow>
                <CCol xs={12}>
                    <div className='d-flex justify-content-between'>
                        <h4>{car.title}&nbsp;
                        <Link to={"/vehiculos/editar/"+id} target='_blank'>
                            <FormOutlined className='iconCameraTable2' title='Editar'/>
                        </Link>
                        </h4>
                        <div className='d-flex align-items-center'>
                            { getStatus(car) }     
                            <div className="progress ms-2" role="progressbar" aria-label="Example with label" aria-valuenow={estadoPro} aria-valuemin="0" aria-valuemax="100">
                                <div className="progress-bar" style={{ width: `${estadoPro}%`}}>{estadoPro}%</div>
                            </div>                       
                        </div>
                    </div>
                    <hr />
                    <form>
                        <CRow>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Marca</label>
                                    <input name="marca" defaultValue={car.make} placeholder="Marca" className='form-control' disabled />
                                </div>
                            </CCol>
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Año</label>
                                    <input name="year" defaultValue={car.year} placeholder="Año" className='form-control' disabled />
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Modelo</label>
                                    <input name="modelo" defaultValue={car.model} placeholder="Modelo" className='form-control' disabled />
                                </div>
                            </CCol> 
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Linea</label>
                                    <input name="version" defaultValue={car.version} placeholder="Linea" className='form-control' disabled />
                                </div>
                            </CCol>                         
                        </CRow>
                        <CRow>     
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Tipo de vehículo</label>
                                    <input name="version" defaultValue={car.vehicle_type} placeholder="Tipo" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Tipo de carroceria</label>
                                    <input name="version" defaultValue={car.vehicle_body_type} placeholder="Carroceria" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Transmisión</label>
                                    <input name="version" defaultValue={car.transmission} placeholder="Transmisión" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Tipo de combustible</label>
                                    <input name="version" defaultValue={car.fuel_type} placeholder="Combustible" className='form-control' disabled/>
                                </div>
                            </CCol>                           
                        </CRow>
                        <CRow> 
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Color</label>
                                    <input name="version" defaultValue={car.color} placeholder="Color" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Kilometraje</label>
                                    <input name="version" value={ moneyFormat(car.kilometers) } placeholder="KM" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Chasis</label>
                                    <input name="chasis" defaultValue={car.chasis} placeholder="Chasis" className='form-control' disabled/>
                                </div>
                            </CCol>  
                            <CCol xs={3}>
                                <div className='form-group'>
                                    <label>Motor</label>
                                    <input name="motor" defaultValue={car.motor} placeholder="Motor" className='form-control' disabled/>
                                </div>
                            </CCol>                           
                        </CRow>
                        <CRow>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Precio</label>
                                    <input name="price" value={ moneyFormat(car.price)} placeholder="Precio" className='form-control' disabled/>
                                </div>
                            </CCol>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Placa</label>
                                    <input name="plate" placeholder="Placa" className='form-control' defaultValue={ car.plate } disabled/>
                                </div>
                            </CCol>
                        </CRow>
                        {
                            car.nro_abonos ?
                            <CRow>
                                <CCol xs={12}>
                                    <div className='form-group'>
                                        <label>
                                            Estado de venta&nbsp;
                                            <Link to={"/car-market/contado/editar/"+id}>
                                                <FormOutlined className='iconCameraTable2' title='Editar'/>
                                            </Link>
                                        </label>
                                    </div>
                                </CCol>
                                <CCol xs={12}>
                                    <CRow>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Comprador </label>
                                                <p className="mb-0">{ moneyFormat(car.id_comprador) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Vendedor</label>
                                                <p className="mb-0">{ moneyFormat(car.id_vendedor) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Tipo de separacion</label>
                                                <p className="mb-0">{ (car.tipo_separacion) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Responsabilidad de gastos</label>
                                                <p className="mb-0">{ (car.resp_gastos) }</p>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow> : null
                        }
                        <CRow>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <label>Descripción</label>
                                    <ReactQuill value={ car.description } readOnly />
                                </div>
                            </CCol>
                            <CCol xs={6}>
                                <div className='form-group'>
                                    <CFormLabel>Foto(s)</CFormLabel>
                                    <CCarousel controls>
                                    {
                                        photos.map(
                                            (item, index) => (
                                                <CCarouselItem>
                                                    <img key={index} src={item} className='d-block w-100' />
                                                </CCarouselItem>
                                            )
                                        )
                                    }
                                    </CCarousel>
                                </div>
                            </CCol>
                        </CRow>
                    </form>                    
                </CCol>
            </CRow>
        </>
    )
}

export default ViewCar;