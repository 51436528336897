import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TripipeService from 'src/services/TripipeService';
import ActasService from 'src/services/ActasService';
import { Col, Modal, Form, message, Input, Select, Button, Checkbox, Flex, Progress, Tag, ColorPicker   } from 'antd';
import { Link  } from "react-router-dom";
import $ from "jquery";
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import Moment from 'moment'
import { CRow } from '@coreui/react';
import DataTable from 'react-data-table-component'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { TextArea } = Input;

const moneyFormat = (value) => "COP "+ new Intl.NumberFormat().format(value);

const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    if(value){
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
        <Tag
            color={value}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
            marginInlineEnd: 4,
            }}
        >
            {label}
        </Tag>
        );
    }
};

const Index = () => {
    const [data, setData] = useState(null);
    const [columnSelected, setColumnSelected] = useState({title: ''});
    const [taskSelected, setTaskSelected] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalLabelOpen, setIsModalLabelOpen] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [isLoading, setIsLoading] = useState(true); 
    const [isEdit, setIsEdit] = useState(false); 
    const [notes, setNotes] = useState([]); 
    const [labels, setLabels] = useState([]); 
    const [users, setUsers] = useState([]); 
    const [clients, setClients] = useState([]); 
    const [dataLabels, setDataLabels] = useState([]);
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const [form_create] = Form.useForm();
    const [flabel] = Form.useForm();
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [ifTasks, setIfTasks] = useState(false);
    const [porcTasks, setPorcTasks] = useState(0);
    const [bitacora, setBitacora] = useState([]);

    const columns = [
        {
            name: 'Nombre',
            selector: rowData => rowData.name
        }, 
        {
            name: 'Color',
            selector: rowData => rowData.color
        },
        {
            name: 'Acciones',
            selector: rowData => {
                return <div className='d-flex justify-content-between'>
                    {/* <Link onClick={ () => editarLabel(rowData) }>
                        Editar
                    </Link> */}
                    &nbsp;&nbsp;
                    <Link onClick={ () => eliminarLabel(rowData) }>
                        Eliminar
                    </Link> 
                </div>
            }
        }
    ]
    
    const eliminarLabel = (_label) =>{
        TripipeService.deleteLabel({id: _label.id}).then(resp =>{
            getLabels();
        })
    }

    const showModalEtiquetas = () =>{
        setIsModalLabelOpen(true)
    }

    const handleEtiCancel = () =>{
        setIsModalLabelOpen(false)
    }

    const showModal = (task) =>{            
        setTaskSelected(task)
        setTitleModal("Editar Deal");
        setIsEdit(true)
        $("[name=notas_add]").val('')
        $("[name=inputSearch]").val('')
        setResults([])
        TripipeService.getTAsk(task.deal_id).then(resp =>{
            setNotes(resp.result.notes)
            setIfTasks(false)
            let _task = resp.result.task[0]
            let _columns = data.columns
            let _column = _columns[task.column]
            setColumnSelected(_column)
            
            if(_task.tareas && _task.tareas.length > 0){
                setIfTasks(true)
                //setPorcTasks
                // Contar el número total de tareas
                const totalTasks = _task.tareas.length;
                // Contar cuántas tareas están completadas
                const completedTasks = _task.tareas.filter(task => task.completed === true).length;
                // Calcular el porcentaje
                const completionPercentage = (completedTasks / totalTasks) * 100;
                setPorcTasks(completionPercentage.toFixed(2))
            }
            let _fields = {
                marca: _task.marca,
                modelo: _task.modelo,
                linea: _task.linea,
                placa: _task.placa,
                costo: _task.cost,
                name_contact: _task.contact,
                miembros: _task.miembros ? (_task.miembros): [],
                etiquetas: _task.labels ? (_task.labels): [],
                tasks: _task.tareas ? (_task.tareas): [],
                descripcion: _task.descripcion
            }
            console.log(_fields)
            
            setLabels(_task.labels)
            form_create.setFieldsValue(_fields)

            TripipeService.getBitacora(task.deal_id).then(bit =>{
                setBitacora(bit.results)
            })
        })
        setIsModalOpen(true)
    }

    const handleOk = () => {
        form_create
        .validateFields()
        .then((values) => {           
            let data = {
                placa: values.placa,
                marca: values.marca,
                modelo: values.modelo,
                linea: values.linea,
                costo: values.costo,
                user_id: authUser().id,
                column_id: columnSelected.id.split("-")[1],
                contact: values.name_contact ? values.name_contact : 0,
                labels: values.etiquetas ? values.etiquetas : [],
                miembros: values.miembros ? values.miembros : [],
                tareas: values.tasks ? JSON.stringify(values.tasks, null, 2): [],
                descripcion: values.descripcion
            }
            
            if(!isEdit){
                TripipeService.addNewTask(data).then(() =>{
                    setIsEdit(true)
                    message.success("Guardado exitosamente");
                    getData();
                })
            }else{                
                let data = {                    
                    contact: values.name_contact ? values.name_contact : 0,
                    labels: values.etiquetas ? values.etiquetas : [],
                    miembros: values.miembros ? values.miembros : [],
                    deal_id: taskSelected.deal_id,
                    tareas: values.tasks ? JSON.stringify(values.tasks, null, 2): [],
                    descripcion: values.descripcion,
                    user_id: authUser().id,
                }
                TripipeService.editTask(data).then(() =>{
                    setIsEdit(true)
                    message.success("Actualizado exitosamente");
                    getData();
                })
            }
            form_create.resetFields();
            setLabels([])
            setNotes([])
            TripipeService.get().then(resp => {
                setData(resp.result);  
                setIsLoading(false);  
            }).catch(error => {
                console.error('Error al obtener los datos:', error);
                setIsLoading(false); 
            });
            setIsModalOpen(false);
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    };
    const handleCancel = () => {
        setLabels([])
        setNotes([])
        form_create.resetFields();
        setIsModalOpen(false);
    };

    const onDragEnd = (result) =>{
        console.log(result)
        const { destination, source, draggableId } = result;
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const startColumn = data.columns[source.droppableId];
        const finishColumn = data.columns[destination.droppableId];

        if (startColumn === finishColumn) {
            const newTaskIds = Array.from(startColumn.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...startColumn,
                taskIds: newTaskIds,
            };

            const newData = {
                ...data,
                columns: {
                  ...data.columns,
                  [newColumn.id]: newColumn,
                },
            };
        
            setData(newData);
        } else {
            const startTaskIds = Array.from(startColumn.taskIds);
            startTaskIds.splice(source.index, 1);
            const newStartColumn = {
              ...startColumn,
              taskIds: startTaskIds,
            };
      
            const finishTaskIds = Array.from(finishColumn.taskIds);
            finishTaskIds.splice(destination.index, 0, draggableId);
            const newFinishColumn = {
              ...finishColumn,
              taskIds: finishTaskIds,
            };
      
            const newData = {
              ...data,
              columns: {
                ...data.columns,
                [newStartColumn.id]: newStartColumn,
                [newFinishColumn.id]: newFinishColumn,
              },
            };
      
            setData(newData);
        }
        let column = destination.droppableId.split("-")[1];
        let task = draggableId.split("_")[1];
        let _data = {
            'column_id': column,
            'task_id': task
        }
        TripipeService.updateDealsPosicion(_data).then(resp => console.log(resp))
    }
    
    const addTask = (_column) =>{
        setLabels([])
        setNotes([])
        setIsEdit(false)
        setColumnSelected(_column)
        form_create.resetFields();
        setTitleModal("Nuevo Deal en "+_column.title);
        setIsModalOpen(true);
    }

    const addNote = () =>{
        if($("[name=notas_add]").val() !== '' && $("[name=notas_add]").val().length > 4){
            let _nota = {
                note: $("[name=notas_add]").val(),
                user_id: authUser().id,
                tri_deal_id: taskSelected.deal_id
            }
            TripipeService.addNewNote(_nota).then(resp =>{
                message.success("Nota agregada con éxito");
                TripipeService.getTAsk(taskSelected.deal_id).then(resp =>{
                    setNotes(resp.result.notes)
                    $("[name=notas_add]").val()
                })
            })
        }else{
            message.error("No se puede crear una nota vacia o de menos de 4 caracteres");
        }
    }

    const searchPlate = (event) =>{
        if(event.target.id === 'basic_placa' && event.target.value.length === 6){
            TripipeService.verifyPlate(event.target.value).then(resp1 =>{
                let _existPlate = resp1.results;
                if(_existPlate.length == 0){
                    ActasService.getByPlate(event.target.value).then(resp =>{
                        let _data = resp.data
                        if(_data.length > 0){
                            _data = _data[0]
                            let _fields = {
                                marca: _data.make,
                                modelo: _data.year,
                                linea: _data.model,
                                costo: _data.price
                            }
                            form_create.setFieldsValue(_fields)
                        }
                    })
                }else{
                    message.error('La placa seleccionada ya se encuentra registrada en Tripipe')
                }
            })
        }
    }

    const getUsers = () =>{
        TripipeService.getUsers().then(resp =>{
            console.log("Users", resp)
            setUsers(resp.users)
        })
    }

    const getLabels = () =>{
        TripipeService.getLabels().then(resp =>{
            setDataLabels(resp.labels)
        })
    }

    const getClients = () =>{
        TripipeService.getClients().then(resp =>{
            setClients(resp.users)
        })
    }

    const onSaveLabel = () =>{
        flabel
        .validateFields()
        .then((values) => {            
            let _data = {
                name: values.nombre,
                color: values.color
            }
            TripipeService.addLabel(_data).then(resp =>{
                flabel.resetFields();
                getLabels()
            })
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    const search = (event) =>{
        if (event.target.value) {
            TripipeService.searchAll(event.target.value).then(resp =>{
                setResults(resp.results)
                setShowResults(true);
            })
        } else {
            setResults([]);
            setShowResults(false);
        }
    }

    const handleBlur = () => {
        setTimeout(() => setShowResults(false), 100); // Añade un pequeño retraso antes de cerrar el menú para permitir hacer clic en los resultados
    };

    const handleFocus = () => {
        if (results.length > 0) {
          setShowResults(true);
        }
    };

    const onChangeCli = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearchCli = (value) => {
        console.log('search:', value);
    };

    const getData = () => {
        TripipeService.get().then(resp => {
          setData(resp.result);  // Guarda los datos obtenidos
          setIsLoading(false);  // Cambia el estado de carga a false
        }).catch(error => {
          console.error('Error al obtener los datos:', error);
          setIsLoading(false);  // Cambia el estado de carga a false incluso si hay un error
        });
    };

    useEffect(() => {  
        if(!authUser().id){
            navigate('/')
        }

        getData();
        getUsers();
        getLabels();
        getClients();
    },[])

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    return(
        <div>
            <Modal className='ctri-modal' title="Etiquetas" width={500} open={isModalLabelOpen} cancelText="Cerrar" onOk={handleEtiCancel} onCancel={handleEtiCancel}>
                <CRow>
                    <Col span={24} className='text-end'>
                        <Form name="flabel" form={flabel}  layout='vertical'>
                            <CRow>
                                <Col span={12}>
                                    <Form.Item label="Nombre" name="nombre"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: Nuevos' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Color" name="color"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        {/* <Input type='text' placeholder='Ej: #FFFFFF' /> */}
                                        <ColorPicker />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Button type="primary" onClick={onSaveLabel}>Guardar</Button>
                                </Col>
                            </CRow>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <DataTable
                            noDataComponent={"No se han encontrado resultados"}
                            columns={ columns }
                            data={dataLabels}
                            pagination 
                            paginationServer
                            responsive             
                        />
                    </Col>
                </CRow>
            </Modal>
            <Modal className='ctri-modal' title={ titleModal } width={700} open={isModalOpen} okText="Guardar" cancelText="Cancelar" onOk={handleOk} onCancel={handleCancel}>
                <Form name="basic" form={form_create} layout='vertical' onChange={ searchPlate }>
                    <CRow className='mb-4'>
                        <Col span={24} className='mb-2'>
                            {dataLabels.filter(item => labels.includes(item.color)).map((item, index) => (
                                <span key={`label-${index}`} style={{ background: item.color, color: '#FFFFFF', padding: '1px 16px'}} className='me-1'>
                                    { item.name }
                                </span>         
                            ))}  
                        </Col>                        
                        <Col span={16}>                        
                            <CRow> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Placa" name="placa"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: RNR549' className='text-uppercase' />
                                    </Form.Item>
                                </Col> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Marca" name="marca"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: Mercedes-Benz'/>
                                    </Form.Item>
                                </Col> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Modelo" name="modelo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: Clase SLK'/>
                                    </Form.Item>
                                </Col> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Linea" name="linea"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: SLK 200'/>
                                    </Form.Item>
                                </Col>
                                <Col md={24} xs={24}>
                                    <Form.Item label="Costo" name="costo">
                                        <Input type='text' placeholder='Ej: 50000000'/>
                                    </Form.Item>
                                </Col>  
                                <Col md={24} xs={24}>
                                    <Form.Item label="Contacto" name="name_contact">
                                        <Select
                                            showSearch
                                            placeholder="Contacto"
                                            optionFilterProp="label"
                                            onChange={onChangeCli}
                                            onSearch={onSearchCli}
                                            options={clients}
                                        />
                                    </Form.Item>
                                </Col>                                
                            </CRow>                                             
                        </Col>                    
                        <Col span={8}>
                            <CRow>
                                <Col span={24}>
                                    <Form.Item label="Miembros" name="miembros">
                                        <Select mode="multiple"
                                            style={{
                                                width: '100%',
                                            }}
                                            options={users}>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Etiquetas" name="etiquetas">
                                        <Select
                                            mode="multiple"
                                            tagRender={tagRender}
                                            style={{
                                                width: '100%',
                                            }}
                                            options={dataLabels}
                                        />
                                    </Form.Item>
                                </Col>
                            </CRow>
                        </Col>                        
                    </CRow>
                    <CRow>
                        <Col md={24} xs={24}>
                            <Form.Item label="Descripcion" name="descripcion">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </CRow>
                    <CRow>
                        <Col>
                            <hr />
                            <p className='lbl-modal mb-2'><strong>Tarea(s)</strong></p>
                            {
                                ifTasks ? 
                                <Flex gap="small" className='mb-3' vertical>
                                    <Progress percent={porcTasks} status="active" />
                                </Flex> : null
                            }                            
                            <Form.List name="tasks">
                            {(fields, { add, remove }) => (
                                <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                        <Form.Item
                                            name={[name, 'completed']}
                                            valuePropName="checked"
                                            className='me-3'
                                            fieldKey={[fieldKey, 'completed']}
                                        >
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            className='w-100'
                                            name={[name, 'task']}
                                            fieldKey={[fieldKey, 'task']}
                                        >
                                            <Input placeholder="Nueva tarea" />
                                        </Form.Item>
                                    <MinusCircleOutlined className='ms-3' onClick={() => remove(name)} />
                                    </div>
                                ))}

                                <Form.Item>
                                    <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                    style={{ marginTop: '10px' }}
                                    >
                                    Nueva tarea
                                    </Button>
                                </Form.Item>
                                </>
                            )}
                            </Form.List>
                            <hr />
                        </Col>
                    </CRow>  
                </Form>
                <CRow>
                    <Col span={24} className='mb-2'>
                        <p className='lbl-modal mb-2'><strong>Notas</strong></p>
                        <textarea className='form-control' name='notas_add' placeholder='Notas'></textarea>
                        <button style={{ float: 'right', marginTop: '4px'}} type='button' className='btn btn-sm btn-primary' onClick={ addNote }>Agregar</button>
                    </Col>
                    <Col span={24}>                           
                        {notes.length > 0 ? notes.map((item, index) => (
                            <dl key={`note-${index}`} className='mb-0 border-bottom'>
                                <dt className='mb-1'><b>{item.names}: </b> <span style={{ float: 'right', fontSize: '12px'}}>{ Moment( item.created_at).format("DD/MM/YYYY h:mm:ss a")}</span></dt>
                                <dd>{ item.note}</dd>
                            </dl>         
                        )) : null}  
                    </Col>
                </CRow>
                <CRow>
                    <Col span={24} className='mb-2'>
                        <p className='lbl-modal mb-2'><strong>Actividad</strong></p>
                    </Col>
                    <Col span={24}>                           
                        {bitacora.length > 0 ? bitacora.map((item, index) => (
                            <dl key={`bitacora-${index}`} className='mb-0 border-bottom'>
                                <dt style={{ fontWeight: '400'}} className='mb-1'>El usuario: <b>{item.names}</b> {item.descripcion}<span style={{ float: 'right', fontSize: '12px'}}>{ Moment( item.created_at).format("DD/MM/YYYY h:mm:ss a")}</span></dt>
                            </dl>         
                        )) : null}  
                    </Col>
                </CRow>
            </Modal>
            <CRow className='text-end mb-2'>
                <Col span={24}>
                    <button className='btn btn-info' onClick={ showModalEtiquetas }>Etiquetas</button>
                </Col>
            </CRow>
            <CRow className='mb-2 btripipe'>
                <Col span={7}></Col>
                <Col span={10}>
                    <div className='text-start'>
                        <input
                            type="text"
                            placeholder="Buscar..."
                            onChange={search}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            name="inputSearch"
                        />
                        {showResults && (
                            <div className="results-dropdown">
                            <ul className='results-list'>
                                {results.map((item, index) => (
                                <li key={index} onClick={() => showModal(item)} className="result-item">
                                    <div className="result-header">
                                        <span className="brand">{ item.title } { item.placa ? ' - '+item.placa : ''}</span>
                                    </div>
                                    <div className="result-price">
                                        { moneyFormat(item.cost) }
                                    </div>
                                        <div className="result-details">
                                        <p className="notes">
                                            <strong>NOTE:</strong> { item.nota }
                                        </p>
                                        { item.cant_nota > 1 ? <p className="notes"> Tiene mas notas (<strong>{ item.cant_nota}</strong>) </p> : '' }
                                        <p className="contact">
                                            { item.name_contact} { ' - '+item.columna}
                                        </p>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                        )}
                    </div>
                </Col>
            </CRow>
            <div className='ctripipe'>
                <CRow>
                    <Col span={24}>
                        <DragDropContext onDragEnd={onDragEnd}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {   
                                data && data.columns && data.columnOrder.map((columnId) => {
                                const column = data.columns[columnId];
                                const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
                                const totalCosto = tasks.reduce((acumulador, objeto) => acumulador + objeto.cost, 0);

                                return (
                                    column ? 
                                    <Droppable droppableId={column.id} key={column.id}>
                                        {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className='column'                                    
                                        >
                                            <div className='d-flex justify-content-between'>
                                                <h3>{column.title}</h3>                                        
                                                <button className='btn btn_add' onClick={() => addTask(column)}>
                                                    +
                                                </button>
                                            </div>
                                            <p className='desp'>{ moneyFormat(totalCosto) } - { tasks.length } deals</p>
                                            {tasks && tasks.map((task, index) => (
                                                task.id ? 
                                                <Draggable key={task.id} draggableId={task.id} index={index} >
                                                    {(provided) => (
                                                    <div onClick={() => showModal(task) }
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps} className='task'>
                                                        <div>
                                                            <h6 className='mb-0'>{ task.content } - <span className='text-uppercase'>{ task.placa }</span></h6>  
                                                            <p className='contact'>{ task.contact ? task.contact : '-' }</p>                                          
                                                            <p className='mb-0'>{ moneyFormat(task.cost ? task.cost : 0) }</p>                                          
                                                        </div>
                                                    </div>
                                                    )}
                                                </Draggable> : null
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                        )}
                                    </Droppable> : null
                                    )
                                })
                            }
                        </div>
                    </DragDropContext>
                    </Col>
                </CRow>  
            </div>          
        </div>
    )
}

export default Index;