import React ,{ useEffect, useState } from 'react';
import { useNavigate, useParams  } from "react-router-dom";
import { CButton, CCol, CRow } from '@coreui/react';
import DataTable from 'react-data-table-component'
import RetomasService from 'src/services/Retoma';
import { useAuthUser } from 'react-auth-kit';
import Moment from 'moment'
import { FormOutlined, EyeOutlined} from '@ant-design/icons';
import { Link  } from "react-router-dom";

const Retomas = () => {
    const navigate = useNavigate();
    const authUser = useAuthUser();
    const [data, setData] = useState([]);
    const moneyFormat = (value) => new Intl.NumberFormat().format(value)+" COP";
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        "search" : '',
        'estado': 'all'
    })
    
    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getData(page, limit, skip);
    }, []);

    const sortByEstado = (rowA, rowB) =>{
        const a = (rowA.estado);
        const b = (rowB.estado);

    
        if (a > b) {
          return 1;
        }
    
        if (b > a) {
          return -1;
        }
    
        return 0;
      }

    const columns = [
        {
          name: 'Fecha',
          selector: rowData => Moment(rowData.created_at).format("DD/MM/YYYY h:mm:ss a")
        },    
        {
          name: 'Vehículo a retomar',
          selector: row => row.vehiculo_retoma,
          sortable: true,
        },
        {
          name: 'Vehículo en venta',
          selector: row => row.title,
          sortable: true,
        },
        {
          name: 'Estado',
          selector: row => statusBodyTemplate(row),
          sortable: true,
          sortFunction: sortByEstado
        },
        {
          name: 'Diferencia neta',
          selector: rowData => moneyFormat(rowData.diferencia_neta),
          sortable: true
        },        
        {
          name: 'Acciones',
          selector: rowData => {
            return <div className='d-flex justify-content-between'>
            {
                rowData.estado !== 'aprobado' && rowData.estado !== 'rechazado' ?
                <div className='text-center cc1'>
                    <Link to={"/retomas/editar/"+rowData.id}>
                    <FormOutlined className='iconCameraTable2' title='Editar'/>
                    </Link>
                </div> : null
            }
            
                <div className='text-center cc1'>
                    <Link to={"/retomas/ver/"+rowData.id}>
                    <EyeOutlined className='iconAprobar' title='Ver'/>
                    </Link>
                </div> 
            </div>
          }
        }
    ];

    const getData = (ppage, _limit) =>{
        setLoading(true);
        let _filter = '';
        if(filters.search !== '') _filter += '&search='+filters.search;
        if(filters.estado !== 'all') _filter += '&estado='+filters.estado;
        RetomasService.getAll(ppage,_limit,skip,_filter).then(resp =>{
            let _data = resp.data;
            setTotalRows(resp.total);
            setData(_data);
            setLoading(false);
        })
    }

    const handlePageChange = page => {
        getData(page, limit);
        setPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setTimeout(() => {
            setLimit(newPerPage)
            getData(page, newPerPage);    
            setLoading(false)
        }, 2000);
    };

    const saveField = (event) =>{
        let _filters = filters;
        switch (event.target.name) {
          case 'search':
            filters.search = event.target.value
            break;
        case 'selectEstado':
            filters.estado = event.target.value
            break;
          default:
            break;
        }
        setFilters(_filters)
        getData(1)
    }

    const statusBodyTemplate = (rowData) => {
        let badge = '';
        switch (rowData.estado) {
            case 'modificado':
                badge = 'warning';
                break;
            case 'por aprobar':
                badge = 'warning';
                break;
            case 'rechazado':
                badge = 'danger';
                break;
            case 'aprobado':
                badge = 'success';
                break;
            default:
                break;
        }
        return <span className={'badge rounded-pill text-bg-'+badge}>{rowData.estado.toUpperCase()}</span>;
    };

    const crearRetoma = () =>{
        navigate("/retomas/crear/")
    }

    return(
        <>
            <CRow>
                <CCol xs={12} className='text-center mb-4'>
                    <h2>Retomas</h2>
                </CCol>
            </CRow>
            <CRow>
                <CCol xs={12}>
                    <div className='fButton'>
                        <CButton onClick={ crearRetoma }>Nueva retoma</CButton>
                    </div>
                </CCol>
            </CRow>
            <div className="row justify-content-end mb-2">
                <div className='col-2'>
                    <div className='form-group'>
                        <label>Estado</label>
                        <select className='form-select' onChange={saveField} name='selectEstado' defaultValue={ filters.estado }>
                        <option value="all">Seleccione</option>
                        <option value={"modificado"}>Modificado</option>
                        <option value={"por aprobar"}>Por aprobar</option>
                        <option value={"rechazado"}>Rechazado</option>
                        <option value={"aprobado"}>Aprobado</option>
                        </select>
                    </div>
                </div>
                <div className='col-2'>
                    <div className='form-group'>
                        <label>&nbsp;</label>
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <input type="search" className='form-control' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
                        </span>
                    </div>
                </div>
            </div>
            <CRow>
                <CCol className='ma-2'>
                <DataTable
                    noDataComponent={"No se han encontrado resultados"}
                    columns={ columns }
                    data={data}
                    progressPending={loading}
                    pagination 
                    paginationServer
                    responsive             
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
                </CCol>
            </CRow>
        </>
    )
}

export default Retomas;